.parent{
    @apply w-full h-screen bg-white flex justify-center items-center h-[100vh] overflow-y-hidden;
}

.font-face-gm {
    font-family: "LoraBold";
  }

  .font-face-DMSans {
    font-family: "DMSansBold";
  }
