@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  overflow-x: hidden;
}

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  color-scheme: light dark;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

@font-face {
  font-family: "LoraBold";
  src: local("LoraBold"),
   url("./fonts/lora/static/Lora-Bold.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "DMSansBold";
  src: local("DMSansBold"),
   url("./fonts/DM_Sans/static/DMSans-Bold.ttf") format("truetype");
  font-weight: bold;
 }
 
